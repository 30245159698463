<template>
  <div class="Enter-box">
     <div class="Derive">
         <div class="Essential-box">
            <ul class="Essential-ul">
               <li @click="Essential()" class="">表单设置</li>
               <li @click="structure()" class="Essential-li">数据导出</li>
            </ul>
         </div>
         <div class="Essential-structure">
            <div>
                <p><span>*</span>表单类型</p>
                <el-select v-model="value" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <el-button type="danger">
                导出
            </el-button>
         </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      radio: '1',
      num: '10',
      options: [{
        value: '客户录入单',
        label: '客户录入单'
      }, {
        value: '采购单',
        label: '采购单'
      }, {
        value: '入库单',
        label: '入库单'
      }, {
        value: '采购发票单',
        label: '采购发票单'
      }, {
        value: '付款单',
        label: '付款单'
      }],
      value: ''
    }
  },
  methods: {
    Essential() {
      this.$router.push('/Approval')
    },
    structure() {
      console.log(1)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    }
  }
}
</script>

<style scoed lang="scss">
.el-main{
  
  background: #FFFFFF;
}
.Derive{
  width: 100%;
  color: #f6f6f3;
  background: url('../../../assets/img/beijing2.png') no-repeat;
  
  background-size: 100% 800px;
  .Essential-box{
    padding: 0 0 0 0;
    display: flex;
    justify-content: space-between;
    .Essential-ul{
       display: flex;
       padding: 38px 0 0 97px;
       li{
          font-size: 20px;
          color: #666666;
          width: 280px;
          font-weight: 500;
       }
       .Essential-li{
           color: #FE5745;
       }
    }

  }
  .Essential-structure{
      & > div{
          padding: 100px 0 0 100px;
          display: flex;
          p{
              font-size: 18px;
              color: #333333;
              margin: 10px 20px 0 0;
              span{
                  color: red;
                  font-size: 18px;
              }
          }
      }
  }

}

</style>
